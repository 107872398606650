@import url(/node_modules/bootstrap-4-grid/scss/grid.scss);
@import url(/node_modules/bootstrap-4-grid/css/grid.min.css);
@import url(https://fonts.googleapis.com/css?family=Nova+Square&display=swap);
@import url(https://fonts.googleapis.com/css?family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css?family=PT+Sans&display=swap);
html, body {
  margin: 0;
  font-family: 'Nova Square', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: black;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.portfolio-container {
    background: #000d1d;
}
.navbar-container {
  position: fixed;
  margin: 0;
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000069;
  z-index: 1;
  -webkit-transition-duration: 1s;
          transition-duration: 1s; }
  .navbar-container > p {
    color: #f7f3ea;
    margin: 0 5px 0 5px; }
  .navbar-container > .navbar-logo-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: logo-animation 6s;
            animation: logo-animation 6s;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #011832; }
    .navbar-container > .navbar-logo-container img {
      width: 30px;
      margin: 10px;
      cursor: pointer; }

@-webkit-keyframes logo-animation {
  0% {
    opacity: 0; }
  80% {
    left: -100px;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }

@keyframes logo-animation {
  0% {
    opacity: 0; }
  80% {
    left: -100px;
    opacity: 0; }
  100% {
    left: 0;
    opacity: 1; } }
  .navbar-container > .navbar-tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 40%;
    -webkit-transition-duration: 1s;
            transition-duration: 1s; }
    .navbar-container > .navbar-tab .tab-container {
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-direction: column;
      height: 100%; }
      .navbar-container > .navbar-tab .tab-container a {
        text-decoration: none; }
      .navbar-container > .navbar-tab .tab-container img {
        width: 25px;
        height: 25px;
        margin: 10px;
        cursor: pointer; }
      .navbar-container > .navbar-tab .tab-container p {
        font-size: 10px;
        text-align: center;
        margin: 0;
        padding: 0;
        padding-top: 5px;
        color: white;
        opacity: 0;
        -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
        -webkit-margin-before: -1em;
                margin-block-start: -1em;
        -webkit-margin-after: -1em;
                margin-block-end: -1em; }
      .navbar-container > .navbar-tab .tab-container:hover p {
        opacity: 1;
        color: #fff16f; }
  .navbar-container > .social-container {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 80px;
    width: 100%;
    right: 0;
    bottom: 0;
    -webkit-transition-duration: 1s;
            transition-duration: 1s; }
    .navbar-container > .social-container img {
      width: 20px;
      margin: 10px;
      cursor: pointer; }

@media (max-width: 578px) {
  .navbar-container {
    height: 60px;
    width: 100%; }
  .navbar-logo-container {
    height: 100% !important;
    width: 50px !important; }
  .navbar-tab {
    flex-direction: row !important;
    height: 100% !important;
    width: 40% !important; }
    .navbar-tab .tab-container p {
      display: none !important; }
  .social-container {
    flex-direction: row !important;
    height: 100% !important;
    width: 80px !important; } }

.skill-container {
  background: radial-gradient(circle, #eeaeca 0%, #94bbe9 100%);
  -webkit-animation: skillAnimation 1s ease-in-out;
          animation: skillAnimation 1s ease-in-out; }

@-webkit-keyframes skillAnimation {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes skillAnimation {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.col-order {
  display: flex; }
  @media (max-width: 992px) {
    .col-order {
      flex-direction: column-reverse; } }

.skill-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; }
  @media (max-width: 992px) {
    .skill-image-container {
      height: 60vh;
      align-items: flex-end; } }

.macbook-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-animation: macbookAnimation 1.7s forwards;
          animation: macbookAnimation 1.7s forwards; }

@-webkit-keyframes macbookAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes macbookAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }
  @media (max-width: 992px) {
    .macbook-container {
      align-items: flex-end; } }
  .macbook-container .macbook-box {
    -webkit-perspective: 100px;
            perspective: 100px;
    height: 70%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center; }
    .macbook-container .macbook-box .macbook {
      height: 100%; }
    .macbook-container .macbook-box .skills {
      position: absolute;
      height: 35%;
      width: 38%;
      -webkit-transform: translateX(55%) translateY(-19%) rotateY(-9deg) rotateZ(-26deg) skewX(-26deg);
              transform: translateX(55%) translateY(-19%) rotateY(-9deg) rotateZ(-26deg) skewX(-26deg); }
      .macbook-container .macbook-box .skills > img {
        width: 100%;
        height: 100%;
        position: absolute;
        -webkit-animation: skill-img 20s infinite 0s;
                animation: skill-img 20s infinite 0s;
        opacity: 0; }
        .macbook-container .macbook-box .skills > img:nth-child(1) {
          -webkit-animation-delay: 2s;
                  animation-delay: 2s; }
        .macbook-container .macbook-box .skills > img:nth-child(2) {
          -webkit-animation-delay: 4s;
                  animation-delay: 4s; }
        .macbook-container .macbook-box .skills > img:nth-child(3) {
          -webkit-animation-delay: 6s;
                  animation-delay: 6s; }
        .macbook-container .macbook-box .skills > img:nth-child(4) {
          -webkit-animation-delay: 8s;
                  animation-delay: 8s; }
        .macbook-container .macbook-box .skills > img:nth-child(5) {
          -webkit-animation-delay: 10s;
                  animation-delay: 10s; }
        .macbook-container .macbook-box .skills > img:nth-child(6) {
          -webkit-animation-delay: 12s;
                  animation-delay: 12s; }
        .macbook-container .macbook-box .skills > img:nth-child(7) {
          -webkit-animation-delay: 14s;
                  animation-delay: 14s; }
        .macbook-container .macbook-box .skills > img:nth-child(8) {
          -webkit-animation-delay: 16s;
                  animation-delay: 16s; }
        .macbook-container .macbook-box .skills > img:nth-child(9) {
          -webkit-animation-delay: 18s;
                  animation-delay: 18s; }

@-webkit-keyframes skill-img {
  0% {
    left: -15%;
    opacity: 0;
    -webkit-transform: scale(0.9) rotateY(-30deg);
            transform: scale(0.9) rotateY(-30deg); }
  5% {
    left: 0;
    opacity: 1;
    -webkit-transform: scale(1) rotateY(0deg);
            transform: scale(1) rotateY(0deg); }
  10% {
    left: 15%;
    opacity: 0;
    -webkit-transform: scale(0.9) rotateY(30deg);
            transform: scale(0.9) rotateY(30deg); } }

@keyframes skill-img {
  0% {
    left: -15%;
    opacity: 0;
    -webkit-transform: scale(0.9) rotateY(-30deg);
            transform: scale(0.9) rotateY(-30deg); }
  5% {
    left: 0;
    opacity: 1;
    -webkit-transform: scale(1) rotateY(0deg);
            transform: scale(1) rotateY(0deg); }
  10% {
    left: 15%;
    opacity: 0;
    -webkit-transform: scale(0.9) rotateY(30deg);
            transform: scale(0.9) rotateY(30deg); } }

.skill-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  align-items: flex-start;
  flex-direction: column; }
  .skill-text-container h2 {
    color: #f7f3ea;
    padding: 0 20px 0 20px; }
  .skill-text-container p {
    background: rgba(121, 121, 121, 0.103);
    color: #3b3b3b;
    padding: 20px;
    border-radius: 20px; }
  @media (max-width: 578px) {
    .skill-text-container {
      margin-top: 60px;
      height: auto; }
      .skill-text-container h2 {
        font-size: 20px; }
      .skill-text-container p {
        font-size: 14px; } }

.header-container {
  background-color: #011832;
  -webkit-animation: backgroundAnimation 1s ease-in-out;
          animation: backgroundAnimation 1s ease-in-out;
  height: 100vh; }

@-webkit-keyframes backgroundAnimation {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes backgroundAnimation {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }
  .header-container .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; }
  .header-container .header-paragraph {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 20vh; }
    .header-container .header-paragraph .text-box {
      width: 270px; }
      .header-container .header-paragraph .text-box > p {
        overflow: hidden;
        /* Ensures the content is not revealed until the animation */
        border-right: 1.5px solid #fff16f;
        /* The typwriter cursor */
        white-space: nowrap;
        /* Keeps the content on a single line */
        margin: 0 auto;
        /* Gives that scrolling effect as the typing happens */
        font-size: 17px;
        color: white;
        padding: 2px;
        -webkit-animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
                animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite; }

@-webkit-keyframes typing {
  from {
    width: 0; }
  to {
    width: 100%; } }

@keyframes typing {
  from {
    width: 0; }
  to {
    width: 100%; } }

@-webkit-keyframes blink-caret {
  from, to {
    border-color: transparent; }
  50% {
    border-color: #fff16f; } }

@keyframes blink-caret {
  from, to {
    border-color: transparent; }
  50% {
    border-color: #fff16f; } }
  @media (max-width: 578px) {
    .header-container .name {
      font-size: 250px; }
    .header-container .surname {
      font-size: 150px; }
    .header-container .header-paragraph > .text-box {
      width: 210px; }
    .header-container .header-paragraph > .text-box > p {
      font-size: 13px; } }

.text--line {
  font-size: 350px;
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
  font-family: sans-serif; }

.text--line2 {
  font-size: 250px;
  font-family: sans-serif; }

svg {
  position: absolute;
  width: 100%;
  height: 100%; }

.text-copy {
  fill: none;
  stroke: #fff16f;
  stroke-dasharray: 10% 40%;
  stroke-width: 4px;
  -webkit-animation: stroke-offset 5s ease-in-out forwards;
          animation: stroke-offset 5s ease-in-out forwards; }
  .text-copy:nth-child(1) {
    stroke: #f7f3ea;
    stroke-dashoffset: 10%; }
  .text-copy:nth-child(2) {
    stroke: #f7f3ea;
    stroke-dashoffset: 20%; }
  .text-copy:nth-child(3) {
    stroke: #f7f3ea;
    stroke-dashoffset: 30%; }
  .text-copy:nth-child(4) {
    stroke: #f7f3ea;
    stroke-dashoffset: 40%; }
  .text-copy:nth-child(5) {
    stroke: #fff16f;
    stroke-dashoffset: 50%; }

@-webkit-keyframes stroke-offset {
  50% {
    stroke-dashoffset: 50%;
    stroke-dasharray: 0 125%; } }

@keyframes stroke-offset {
  50% {
    stroke-dashoffset: 50%;
    stroke-dasharray: 0 125%; } }

.works-logo {
  z-index: 2;
  position: fixed;
  right: 0;
  top: 0;
  margin: 20px;
  opacity: 0;
  -webkit-animation: worksLogoAnimation 1.3s ease-in-out forwards;
          animation: worksLogoAnimation 1.3s ease-in-out forwards;
  font-family: 'Monoton', regular;
  color: #fff16f;
  font-size: 55px; }
  @media (max-width: 568px) {
    .works-logo {
      top: 70px;
      font-size: 40px; } }

@-webkit-keyframes worksLogoAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes worksLogoAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.works-container {
  -webkit-animation: worksAnimation 1s ease-in-out;
          animation: worksAnimation 1s ease-in-out;
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  width: 100vw;
  background: -webkit-gradient(linear, right top, left top, from(#3a1c71), color-stop(#d76d77), to(#ffaf7b));
  background: linear-gradient(to left, #3a1c71, #d76d77, #ffaf7b);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

@-webkit-keyframes worksAnimation {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes worksAnimation {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }
  .works-container > .works-background {
    background: -webkit-gradient(linear, right top, left top, from(#3a1c71), color-stop(#d76d77), to(#ffaf7b));
    background: linear-gradient(to left, #3a1c71, #d76d77, #ffaf7b);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    height: 100vh;
    width: 100%;
    position: fixed; }
  .works-container > .work-container {
    height: 100%;
    width: 100%;
    scroll-snap-align: center; }

.scroll-image {
  position: absolute;
  bottom: 0;
  left: calc(50vw - 20px);
  width: 30px;
  margin: 10px; }

.lpr-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%; }
  .lpr-card-container .lpr-card {
    outline: none;
    text-decoration: none;
    color: black;
    position: absolute;
    width: 450px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    box-shadow: 13px 10px 63px -26px rgba(0, 0, 0, 0.75);
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    cursor: pointer; }
    .lpr-card-container .lpr-card:hover {
      background-color: rgba(255, 255, 255, 0.699); }
  .lpr-card-container > img {
    width: 400px;
    position: relative;
    -webkit-animation: animationCircleLeft 100s infinite;
            animation: animationCircleLeft 100s infinite; }

@-webkit-keyframes animationCircleLeft {
  0% {
    margin-left: 0; }
  2% {
    margin-left: 450px;
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0); }
  100% {
    margin-left: 450px;
    -webkit-transform: rotateZ(5000deg);
            transform: rotateZ(5000deg); } }

@keyframes animationCircleLeft {
  0% {
    margin-left: 0; }
  2% {
    margin-left: 450px;
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0); }
  100% {
    margin-left: 450px;
    -webkit-transform: rotateZ(5000deg);
            transform: rotateZ(5000deg); } }

@-webkit-keyframes animationCircleBottom {
  0% {
    margin-top: 0; }
  2% {
    margin-top: 300px;
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0); }
  100% {
    margin-top: 300px;
    -webkit-transform: rotateZ(5000deg);
            transform: rotateZ(5000deg); } }

@keyframes animationCircleBottom {
  0% {
    margin-top: 0; }
  2% {
    margin-top: 300px;
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0); }
  100% {
    margin-top: 300px;
    -webkit-transform: rotateZ(5000deg);
            transform: rotateZ(5000deg); } }
    @media (max-width: 768px) {
      .lpr-card-container > img {
        width: 200px;
        -webkit-animation: animationCircleBottom 100s infinite;
                animation: animationCircleBottom 100s infinite; } }

.lpr-text-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh; }
  .lpr-text-container > .lpr-text {
    width: 50%; }
  .lpr-text-container p {
    background: rgba(255, 255, 255, 0.103);
    color: white;
    padding: 20px;
    border-radius: 20px; }

@media (max-width: 768px) {
  .lpr-card-container {
    height: 50vh; }
    .lpr-card-container > .lpr-card {
      margin-top: 80px;
      position: absolute;
      width: 90%;
      height: 150px; }
  .lpr-text-container {
    height: 50vh;
    align-items: flex-end; }
    .lpr-text-container > .lpr-text {
      width: 100%;
      margin-bottom: 50px; }
    .lpr-text-container p {
      font-size: 14px; } }

.rn-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  -webkit-perspective: 1000px;
          perspective: 1000px; }
  .rn-card-container .rn-card {
    -webkit-transform: rotateY(20deg) rotateX(15deg) translateX(5%);
            transform: rotateY(20deg) rotateX(15deg) translateX(5%);
    outline: none;
    text-decoration: none;
    color: white;
    position: absolute;
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #222222;
    box-shadow: 13px 10px 63px -26px rgba(0, 0, 0, 0.75);
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    cursor: pointer; }
    .rn-card-container .rn-card video {
      width: 30%;
      display: inline-block;
      padding: 5px 5px  5px 0; }
    .rn-card-container .rn-card img {
      width: 70%;
      padding: 5px;
      display: inline-block; }
    .rn-card-container .rn-card:hover {
      background-color: rgba(34, 34, 34, 0.699); }

.rn-text-container {
  display: none;
  justify-content: flex-end;
  align-items: center;
  height: 100vh; }
  .rn-text-container > .rn-text {
    width: 50%; }
  .rn-text-container p {
    background: rgba(255, 255, 255, 0.103);
    color: white;
    padding: 20px;
    border-radius: 20px; }

@media (max-width: 768px) {
  .rn-card-container {
    height: 50vh; }
    .rn-card-container > .rn-card {
      margin-top: 100px;
      width: 90%; }
  .rn-text-container {
    display: flex;
    height: 50vh;
    align-items: flex-end; }
    .rn-text-container > .rn-text {
      width: 100%;
      margin-bottom: 50px; }
    .rn-text-container p {
      font-size: 14px; } }

.tale-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%; }
  .tale-card-container .tale-card {
    outline: none;
    text-decoration: none;
    color: #222222;
    position: relative;
    width: 450px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    box-shadow: 13px 10px 63px -26px rgba(0, 0, 0, 0.75);
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    cursor: pointer; }
    .tale-card-container .tale-card:hover {
      background-color: rgba(255, 255, 255, 0.699); }
  .tale-card-container img {
    width: 250px;
    position: relative; }
    @media (max-width: 768px) {
      .tale-card-container img {
        width: 250px; } }

.tale-text-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh; }
  .tale-text-container > .tale-text {
    width: 50%; }
  .tale-text-container p {
    background: rgba(255, 255, 255, 0.103);
    color: white;
    padding: 20px;
    border-radius: 20px; }

@media (max-width: 768px) {
  .tale-card-container {
    height: 50vh; }
    .tale-card-container > .tale-card {
      margin-top: 180px;
      width: 90%;
      height: 150px; }
  .tale-text-container {
    height: 50vh;
    align-items: flex-end; }
    .tale-text-container > .tale-text {
      width: 100%;
      margin-bottom: 50px; }
    .tale-text-container p {
      font-size: 14px; } }

.gravity-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%; }
  .gravity-card-container .gravity-card {
    outline: none;
    text-decoration: none;
    color: #222222;
    position: relative;
    width: 450px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    box-shadow: 13px 10px 63px -26px rgba(0, 0, 0, 0.75);
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    cursor: pointer; }
    .gravity-card-container .gravity-card > h1 {
      z-index: 1;
      padding: 20px;
      background-color: #ffaa94;
      border: 3px solid black;
      color: white; }
    .gravity-card-container .gravity-card p {
      z-index: 1; }
    .gravity-card-container .gravity-card > div {
      -webkit-transform: translateY(30%) translateX(50%);
              transform: translateY(30%) translateX(50%);
      position: absolute;
      height: 150px;
      width: 150px;
      background-color: #daf3ff; }
    .gravity-card-container .gravity-card:hover {
      background-color: rgba(255, 255, 255, 0.699); }
  .gravity-card-container img {
    width: 250px;
    position: relative; }
    @media (max-width: 768px) {
      .gravity-card-container img {
        width: 250px; } }

.gravity-text-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh; }
  .gravity-text-container > .gravity-text {
    width: 50%; }
  .gravity-text-container p {
    background: rgba(255, 255, 255, 0.103);
    color: white;
    padding: 20px;
    border-radius: 20px; }

@media (max-width: 768px) {
  .gravity-card-container {
    height: 50vh; }
    .gravity-card-container > .gravity-card {
      margin-top: 180px;
      width: 90%;
      height: 150px; }
  .gravity-text-container {
    height: 50vh;
    align-items: flex-end; }
    .gravity-text-container > .gravity-text {
      width: 100%;
      margin-bottom: 50px; }
    .gravity-text-container p {
      font-size: 14px; } }

.profile-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: -webkit-gradient(linear, right top, left top, from(#43c6ac), to(#f8ffae));
  background: linear-gradient(to left, #43c6ac, #f8ffae);
  -webkit-animation: profileAnimation 1s ease-in-out;
          animation: profileAnimation 1s ease-in-out; }

@-webkit-keyframes profileAnimation {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes profileAnimation {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }
  .profile-container .profile-box-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    .profile-container .profile-box-container > img {
      width: 300px;
      position: absolute;
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      opacity: 0;
      -webkit-animation: photoAnimation 1.5s forwards ease-in-out;
              animation: photoAnimation 1.5s forwards ease-in-out; }

@-webkit-keyframes photoAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateX(-200%) translateY(-20%);
            transform: translateX(-200%) translateY(-20%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(-100%) translateY(-20%);
            transform: translateX(-100%) translateY(-20%); } }

@keyframes photoAnimation {
  from {
    opacity: 0;
    -webkit-transform: translateX(-200%) translateY(-20%);
            transform: translateX(-200%) translateY(-20%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(-100%) translateY(-20%);
            transform: translateX(-100%) translateY(-20%); } }
    .profile-container .profile-box-container > div {
      background: white;
      width: 650px;
      opacity: 0;
      height: 400px;
      -webkit-animation: infoBoxAniamtion 1s forwards ease-in-out;
              animation: infoBoxAniamtion 1s forwards ease-in-out; }

@-webkit-keyframes infoBoxAniamtion {
  from {
    opacity: 0;
    -webkit-transform: translateX(200%);
            transform: translateX(200%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes infoBoxAniamtion {
  from {
    opacity: 0;
    -webkit-transform: translateX(200%);
            transform: translateX(200%); }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }
      .profile-container .profile-box-container > div .profile-info-container {
        display: flex;
        align-items: center; }
        .profile-container .profile-box-container > div .profile-info-container h5 {
          padding: 10px;
          border-radius: 20px;
          background: #e6e6e6;
          cursor: pointer;
          -webkit-transition-duration: .5s;
                  transition-duration: .5s; }
          .profile-container .profile-box-container > div .profile-info-container h5:hover {
            background: #ffbcb4; }
        .profile-container .profile-box-container > div .profile-info-container p {
          color: gray;
          margin: 20px; }
        .profile-container .profile-box-container > div .profile-info-container img {
          height: 20px;
          width: 20px; }
  @media (max-width: 578px) {
    .profile-container {
      min-height: 100vh; }
      .profile-container .profile-box-container {
        height: auto;
        margin-top: 100px;
        align-items: flex-start; }
        .profile-container .profile-box-container > img {
          -webkit-transform: translateX(60%) translateY(60%);
                  transform: translateX(60%) translateY(60%);
          width: 45%;
          -webkit-animation: photoAnimation 1.2s forwards ease-in-out;
                  animation: photoAnimation 1.2s forwards ease-in-out;
          -webkit-animation-delay: 0.4s;
                  animation-delay: 0.4s; }
    @-webkit-keyframes photoAnimation {
      from {
        opacity: 0;
        -webkit-transform: translateX(250%) translateY(60%);
                transform: translateX(250%) translateY(60%); }
      to {
        opacity: 1;
        -webkit-transform: translateX(60%) translateY(60%);
                transform: translateX(60%) translateY(60%); } }
    @keyframes photoAnimation {
      from {
        opacity: 0;
        -webkit-transform: translateX(250%) translateY(60%);
                transform: translateX(250%) translateY(60%); }
      to {
        opacity: 1;
        -webkit-transform: translateX(60%) translateY(60%);
                transform: translateX(60%) translateY(60%); } }
        .profile-container .profile-box-container > div {
          height: 100%; }
          .profile-container .profile-box-container > div h1 {
            font-size: 25px; }
          .profile-container .profile-box-container > div p {
            font-size: 14px; } }

.pdf-modal-container {
  background: #00000069;
  position: absolute;
  height: 100vh;
  width: 100vw;
  padding: 50px 0 50px 0;
  overflow: hidden; }
  .pdf-modal-container .close-modal-curriculum-button {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    cursor: pointer;
    border-radius: 20px;
    border: none;
    color: black;
    background: white;
    height: 30px;
    padding: 10px;
    margin: 5px; }
    .pdf-modal-container .close-modal-curriculum-button:hover {
      background: #43c6ac;
      color: white; }
  .pdf-modal-container .pdfSize {
    display: flex;
    justify-content: center; }
    .pdf-modal-container .pdfSize > canvas {
      width: auto !important;
      height: 80vh !important; }
  @media (max-width: 599px) {
    .pdf-modal-container {
      padding: 100px 0 0 0; }
      .pdf-modal-container .pdfSize > canvas {
        width: 90vw !important;
        height: auto !important; } }

.hidePdfModal {
  display: none; }

