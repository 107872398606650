$first-color: rgb(255, 241, 111);
$second-color: rgb(247, 243, 234);
$third-color: #011832;

.works-logo {
    z-index: 2;
    position: fixed;
    right: 0;
    top: 0;
    margin: 20px;
    opacity: 0;
    animation: worksLogoAnimation 1.3s ease-in-out forwards;
    font-family: 'Monoton', regular;
    color: $first-color;
    font-size: 55px;
    @media(max-width: 568px) {
        top: 70px;
        font-size: 40px;
    }
    @keyframes worksLogoAnimation {
        from {
            opacity: 0;
            transform: translateX(150%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

.works-container {
    animation: worksAnimation 1s ease-in-out;
    scroll-snap-type: y mandatory;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(to left, #3a1c71, #d76d77, #ffaf7b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    @keyframes worksAnimation {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }

    > .works-background {
        background: linear-gradient(to left, #3a1c71, #d76d77, #ffaf7b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        height: 100vh;
        width: 100%;
        position: fixed;
    }
    
    >.work-container {
        height: 100%;
        width: 100%;
        scroll-snap-align: center;
    }
}

