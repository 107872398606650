@import "/node_modules/bootstrap-4-grid/scss/grid.scss";
@import "/node_modules/bootstrap-4-grid/css/grid.min.css";
@import url('https://fonts.googleapis.com/css?family=Nova+Square&display=swap');
@import url('https://fonts.googleapis.com/css?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css?family=PT+Sans&display=swap');

html, body {
  margin: 0;
  font-family: 'Nova Square', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: black;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
