.profile-container {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background: linear-gradient(to left, #43c6ac, #f8ffae);
    animation: profileAnimation 1s ease-in-out;
    @keyframes profileAnimation {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }
    .profile-box-container {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        >img {
            width: 300px;
            position: absolute;
            filter: grayscale(100%);
            opacity: 0;
            animation: photoAnimation 1.5s forwards ease-in-out;
            @keyframes photoAnimation {
                from {
                    opacity: 0;
                    transform: translateX(-200%) translateY(-20%);
                }
                to {
                    opacity: 1;
                    transform: translateX(-100%) translateY(-20%);
                }
            }
        }
        > div {
            background: white;
            width: 650px;
            opacity: 0;
            height: 400px;
            animation: infoBoxAniamtion 1s forwards ease-in-out;
            @keyframes infoBoxAniamtion {
                from {
                    opacity: 0;
                    transform: translateX(200%);
                }
                to {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
            .profile-info-container {
                display: flex;
                align-items: center;
                h5 {
                    padding: 10px;
                    border-radius: 20px;
                    background: rgb(230, 230, 230);
                    cursor: pointer;
                    transition-duration: .5s;
                    &:hover {
                        background: rgb(255, 188, 180);
                    }
                }
                p {
                    color: gray;
                    margin: 20px;
                }
                img {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
    @media(max-width: 578px) {
        min-height: 100vh;
        .profile-box-container {
            height: auto;
            margin-top: 100px;
            align-items: flex-start;
            >img {
                transform:translateX(60%) translateY(60%);
                width: 45%;
                animation: photoAnimation 1.2s forwards ease-in-out;
                animation-delay: 0.4s;
                @keyframes photoAnimation {
                    from {
                        opacity: 0;
                        transform: translateX(250%) translateY(60%);
                    }
                    to {
                        opacity: 1;
                        transform: translateX(60%) translateY(60%);
                    }
                }
            }
            > div {
                height: 100%;
                h1 {
                    font-size: 25px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
}