.pdf-modal-container {
    background: #00000069;
    position: absolute;
    height: 100vh;
    width: 100vw;
    padding: 50px 0 50px 0;
    overflow: hidden;
    .close-modal-curriculum-button {
        display: flex;
        justify-content: center;
        align-items: center;
        transition-duration: 0.3s;
        cursor: pointer;
        border-radius: 20px;
        border: none;
        color: black;
        background: white;
        height: 30px;
        padding: 10px;
        margin: 5px;
        &:hover {
            background: #43c6ac;
            color: white;
        }
    }
    .pdfSize {
        display: flex;
        justify-content: center;
        & > canvas {
            width: auto !important;
            height: 80vh !important;
        }
    }
    @media (max-width: 599px) {
        padding: 100px 0 0 0;
        .pdfSize {
            & > canvas {
                width: 90vw !important;
                height: auto !important;
            }
        }
    }
}

.hidePdfModal {
    display: none;
}