$first-color: rgb(255, 241, 111);
$second-color: rgb(247, 243, 234);
$third-color: #011832;

@mixin centred {
    display: flex;
    justify-content: center;
    align-items: center;
}

.skill-container {
    // height: 100vh;
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    animation: skillAnimation 1s ease-in-out;
    @keyframes skillAnimation {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }
}

.col-order {
    display: flex;
    @media(max-width: 992px) {
        flex-direction: column-reverse;
    }
}

.skill-image-container {
    @include centred;
    height: 100vh;
    @media (max-width: 992px) {
        height: 60vh;
        align-items: flex-end;
    }
}

.macbook-container {
    @include centred;
    height: 100%;
    width: 100%;
    opacity: 0;
    animation: macbookAnimation 1.7s forwards;
    // animation-delay: 1s;
    @keyframes macbookAnimation {
        from {
            opacity: 0;
            transform: translateX(150%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @media(max-width: 992px) {
        align-items: flex-end;
    }
    .macbook-box {
        perspective: 100px;
        height: 70%;
        position: absolute;
        @include centred;
        .macbook {
            height: 100%;
        }
        .skills {
            position: absolute;
            height: 35%;
            width: 38%;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            transform: 
                translateX(55%) 
                translateY(-19%) 
                rotateY(-9deg) 
                rotateZ(-26deg) 
                skewX(-26deg);
            > img {
                width: 100%;
                height: 100%;
                position: absolute;
                animation: skill-img 20s infinite 0s;
                opacity: 0;
                &:nth-child(1) {
                    animation-delay: 2s;
                }
                &:nth-child(2) {
                    animation-delay: 4s;
                }
                &:nth-child(3) {
                    animation-delay: 6s;
                }
                &:nth-child(4) {
                    animation-delay: 8s;
                }
                &:nth-child(5) {
                    animation-delay: 10s;
                }
                &:nth-child(6) {
                    animation-delay: 12s;
                }
                &:nth-child(7) {
                    animation-delay: 14s;
                }
                &:nth-child(8) {
                    animation-delay: 16s;
                }
                &:nth-child(9) {
                    animation-delay: 18s;
                }
                @keyframes skill-img {
                    0% {
                        left: -15%;
                        opacity: 0;
                        transform: scale(0.9) rotateY(-30deg);
                    }
                    5% {
                        left: 0;
                        opacity: 1;
                        transform: scale(1) rotateY(0deg);
                    }
                    10% {
                        left: 15%;
                        opacity: 0;
                        transform: scale(0.9) rotateY(30deg);
                    }
                }
            }
        }
    }
}

.skill-text-container {
    @include centred;
    height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    h2 {
        color: $second-color;
        padding: 0 20px 0 20px;
    }
    p {
        background: rgba(121, 121, 121, 0.103);
        color: rgb(59, 59, 59);
        padding: 20px;
        border-radius: 20px;
    }
    @media(max-width:578px) {
        margin-top: 60px;
        height: auto;
        h2 {
                font-size: 20px;
        }
        p {
            font-size: 14px;
        }
    }
}