.rn-card-container {
    $size: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    perspective: 1000px;
    .rn-card {
        transform: rotateY(20deg) rotateX(15deg)  translateX(5%) ;
        outline: none;
        text-decoration: none;
        color: rgb(255, 255, 255);
        position: absolute;
        width: 90%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(34, 34, 34);
        box-shadow: 13px 10px 63px -26px rgba(0,0,0,0.75);
        transition-duration: 1s;
        cursor: pointer;
        video {
            width: 30%;
            display: inline-block;
            padding:5px 5px  5px 0;
        }
        img {
            width: 70%;
            padding: 5px;
            display: inline-block;
        }
        &:hover {
            background-color: rgba(34, 34, 34, 0.699);
        }
    }
}

.rn-text-container {
    display: none;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
    > .rn-text {
        width: 50%;
    }
    p {
        background: rgba(255, 255, 255, 0.103);
        color: rgb(255, 255, 255);
        padding: 20px;
        border-radius: 20px;
    }
}

@media(max-width: 768px) {
    .rn-card-container {
        height: 50vh;
        > .rn-card {
            // top: 170px;
            // position: absolute;
            margin-top: 100px;
            // padding-top: 50px;
            width: 90%;
        }
    }
    .rn-text-container {
        display: flex;
        height: 50vh;
        align-items: flex-end;
        > .rn-text {
            width: 100%;
            margin-bottom: 50px;
        }
        p {
            font-size: 14px;
        }
    }
}