$first-color: rgb(255, 241, 111);
$second-color: rgb(247, 243, 234);
$third-color: #011832;

.navbar-container {
    position: fixed;
    margin: 0;
    height: 100%;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000069;
    z-index: 1;
    transition-duration: 1s;

    > p {
        color: $second-color;
        margin: 0 5px 0 5px;
    }
    > .navbar-logo-container {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        animation: logo-animation 6s;
        // animation-delay: 5s;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $third-color;
        img {
            width: 30px;
            margin: 10px;
            cursor: pointer;
        }
    }
    @keyframes logo-animation {
        0% {
            opacity: 0;
        }
        80% {
            left: -100px;
            opacity: 0;
        }
        100% {
            left: 0;
            opacity: 1;
        }
    }
    > .navbar-tab {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 40%;
        transition-duration: 1s;
        .tab-container {
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-direction: column;
            height: 100%;
            a {
                text-decoration: none;
            }
            img {
                width: 25px;
                height: 25px;
                margin: 10px;
                cursor: pointer;
            }
            p {
                font-size: 10px;
                text-align: center;
                margin: 0;
                padding: 0;
                padding-top: 5px;
                color: white;
                opacity: 0;
                transition-duration: 0.5s;
                margin-block-start: -1em;
                margin-block-end: -1em;
            }
            &:hover {
                p {
                    opacity: 1;
                    color: $first-color;
                }
            }
        }
    }
    > .social-container {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 80px;
        width: 100%;
        right: 0;
        bottom: 0;
        transition-duration: 1s;
        img {
            width: 20px;
            margin: 10px;
            cursor: pointer;
        }
    }
}

@media(max-width: 578px) {
    .navbar-container {
        height: 60px;
        width: 100%;
    }
    .navbar-logo-container {
        height: 100% !important;
        width: 50px !important;
    }
    .navbar-tab {
        flex-direction: row !important;
        height: 100% !important;
        width: 40% !important;
        .tab-container {
            p {
                display: none !important;
            }
        }
    }
    .social-container {
        flex-direction: row !important;
        height: 100% !important;
        width: 80px !important;
    }
}