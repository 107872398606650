$colors: 
    rgb(247, 243, 234), 
    rgb(247, 243, 234), 
    rgb(247, 243, 234),
    rgb(247, 243, 234), 
    rgb(255, 241, 111);

$max: 5;
$stroke-step: 10%; 

.text--line {
    font-size: 350px;
    transform: scale(-1, 1);
    font-family: sans-serif;
}

.text--line2 {
    font-size: 250px;
    font-family: sans-serif;
}

svg {
    position: absolute;
    width: 100%;
    height: 100%;
}

.text-copy {
    fill: none;
    stroke: rgb(255, 241, 111);
    stroke-dasharray: $stroke-step $stroke-step * ($max - 1);
    stroke-width: 4px;
    animation: stroke-offset 5s ease-in-out forwards;
    
    @for $item from 1 through $max {
        $stroke-color: nth($colors, $item);
        
        &:nth-child(#{$item}) {
            stroke: $stroke-color;
            stroke-dashoffset: $stroke-step * $item;
        }
    }
}

@keyframes stroke-offset {
    50% {
        stroke-dashoffset: $stroke-step * $max;  
        stroke-dasharray: 0 $stroke-step * $max*2.5;
    }
}