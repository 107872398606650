.tale-card-container {
    $size: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    .tale-card {
        outline: none;
        text-decoration: none;
        color: rgb(34, 34, 34);
        position: relative;
        width: $size;
        height: $size;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgb(255, 255, 255);
        box-shadow: 13px 10px 63px -26px rgba(0,0,0,0.75);
        transition-duration: 1s;
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 255, 255, 0.699);
        }
    }
    img {
        width: $size - 200px;
        position: relative;
        @media(max-width: 768px) {
            width: 250px;
        }
    }
}

.tale-text-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
    > .tale-text {
        width: 50%;
    }
    p {
        background: rgba(255, 255, 255, 0.103);
        color: rgb(255, 255, 255);
        padding: 20px;
        border-radius: 20px;
    }
}

@media(max-width: 768px) {
    .tale-card-container {
        height: 50vh;
        > .tale-card {
            margin-top: 180px;
            width: 90%;
            height: 150px;
        }
    }
    .tale-text-container {
        height: 50vh;
        align-items: flex-end;
        > .tale-text {
            width: 100%;
            margin-bottom: 50px;
        }
        p {
            font-size: 14px;
        }
    }
}