.scroll-image {
    position: absolute;
    bottom: 0;
    left: calc(50vw - 20px);
    width: 30px;
    margin: 10px;
}

.lpr-card-container {
    $size: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    .lpr-card {
        outline: none;
        text-decoration: none;
        color: black;
        position: absolute;
        width: $size;
        height: $size;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: white;
        box-shadow: 13px 10px 63px -26px rgba(0,0,0,0.75);
        transition-duration: 1s;
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 255, 255, 0.699);
        }
    }
    > img {
        width: $size - 50px;
        position: relative;
        animation: animationCircleLeft 100s infinite;
        @keyframes animationCircleLeft {
            0% {
                margin-left: 0;
            }
            2% {
                margin-left: $size ;
                transform: rotateZ(0);
            }
            100% {
                margin-left: $size ;
                transform: rotateZ(5000deg);
            }
        }
        @keyframes animationCircleBottom {
            0% {
                margin-top: 0;
            }
            2% {
                margin-top: 300px ;
                transform: rotateZ(0);
            }
            100% {
                margin-top: 300px ;
                transform: rotateZ(5000deg);
            }
        }
        @media(max-width: 768px) {
            width: 200px;
            animation: animationCircleBottom 100s infinite;
        }
    }
}

.lpr-text-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
    > .lpr-text {
        width: 50%;
    }
    p {
        background: rgba(255, 255, 255, 0.103);
        color: rgb(255, 255, 255);
        padding: 20px;
        border-radius: 20px;
    }
}

@media(max-width: 768px) {
    .lpr-card-container {
        height: 50vh;
        > .lpr-card {
            margin-top: 80px;
            position: absolute;
            width: 90%;
            height: 150px;
        }
    }
    .lpr-text-container {
        height: 50vh;
        align-items: flex-end;
        > .lpr-text {
            width: 100%;
            margin-bottom: 50px;
        }
        p {
            font-size: 14px;
        }
    }
}