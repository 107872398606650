$first-color: rgb(255, 241, 111);
$second-color: rgb(247, 243, 234);
$third-color: #011832;

.header-container {
    background-color:$third-color;
    animation: backgroundAnimation 1s ease-in-out;
    height: 100vh;

    @keyframes backgroundAnimation {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }

    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
    }


    .header-paragraph {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 20vh;

        .text-box {
            width: 270px;
            & > p {
                overflow: hidden; /* Ensures the content is not revealed until the animation */
                border-right: 1.5px solid $first-color; /* The typwriter cursor */
                white-space: nowrap; /* Keeps the content on a single line */
                margin: 0 auto; /* Gives that scrolling effect as the typing happens */
                font-size: 17px;
                color: white;
                padding: 2px;
                animation: 
                typing 3.5s steps(30, end),
                blink-caret .75s step-end infinite;
                @keyframes typing {
                    from { width: 0 }
                    to { width: 100% }
                }
                
                @keyframes blink-caret {
                    from, to { border-color: transparent }
                    50% { border-color: $first-color; }
                }
            }
        }
    }

    @media(max-width: 578px) {
        .name {
            font-size: 250px;
        }
        .surname {
            font-size: 150px;
        }
        .header-paragraph > .text-box{
            width: 210px;
        }
        .header-paragraph > .text-box > p {
            font-size: 13px;
        }
    }
}